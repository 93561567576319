import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import './index.scss';
import { StockLimitTag } from '../../../stock/components/LimitTag';
import Table, { ColumnProps, TableProps } from 'app/components/Table';
import moment from 'moment';
import SearchInput from 'stock/components/SearchInput';
import { PredictAggregation } from 'ai/models';
import { fetchModelPredictsAggregation } from 'ai/api';
import AIModePredictCard from 'ai/components/ModelPredictCard';
import _ from 'lodash';
import ClassifyTag from 'classify/components/ClassifyTag';
import { StockDailyColumns } from 'stock/components/StockTableComponents';
import { sortPredicts } from 'ai/utils';
import { ApiResult } from 'app/services/api';
import ConceptSectors from 'classify/components/ConceptSectors';

export const COLUMNS: ColumnProps<PredictAggregation>[] = [
  {
    title: '日期',
    dataIndex: 'date',
    render: (_, record) => moment(record.date).format('YYYY-MM-DD'),
    valueType: 'dateRange',
    sorter: (a, b) => a.date - b.date,
    fixed: 'left',
    width: 100,
    defaultSortOrder: 'descend',
  },
  {
    title: '股票',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
    width: 150,
    renderFormItem(item, config, form) {
      return (
        <SearchInput
          onSelect={(value) => {
            form.setFieldsValue({ [item.dataIndex as string]: value.split(' ')[0] });
          }}
          search={false}
        />
      );
    },
    render: (_, record) => (
      <Link to={`/stocks/${record.info.code}`} target="_blank">
        {record.info.name}
        <br />
        {record.info.code}
        <StockLimitTag stock={record} />
        <br />
        <ClassifyTag {...record.industry_sector} />
        <ConceptSectors sectors={record.concept_sectors} />
      </Link>
    ),
    sorter: false,
  },
  {
    title: '综合推荐',
    fixed: 'left',
    dataIndex: 'confidence',
    width: 100,
    search: false,
    render: (_, r) => r.confidence,
    sorter: (a, b) => a.confidence - b.confidence,
    defaultSortOrder: 'descend',
  },
  {
    title: '日均期望',
    fixed: 'left',
    dataIndex: 'model_expected',
    search: false,
    width: 100,
    render: (_, r) => {
      return <>
      平均: {r.daily_expected.avg.toFixed(2)}% <br />
      最大: {r.daily_expected.max.toFixed(2)}% <br />
      最小: {r.daily_expected.min.toFixed(2)}%
      </>
    }
  },
  {
    title: '预测',
    dataIndex: 'prob',
    render: (_, r) => {
      const sortedProbs = sortPredicts(r.probs);
      return (
        <div className="predicts">
          {sortedProbs.map((prob) => (
            <AIModePredictCard predict={prob} />
          ))}
        </div>
      );
    },
    sorter: (a, b) => {
      // 首先比较大于0.9的预测数
      // 然后比较大于0.8的预测数
      // 然后比较平均日均收益
      // 实际上0.8-0.8之间的预测只是作为一个参考，不应该作为排序的依据
      // 0.8-0.9之间的预测，因为精确度实际上都是0.9以上的，所以也不纳入平均收益的计算
      const aHighProb = a.probs.filter((prob) => prob.prob >= 0.9);
      const bHighProb = b.probs.filter((prob) => prob.prob >= 0.9);
      if (aHighProb.length !== bHighProb.length) {
        return aHighProb.length - bHighProb.length;
      } else {
        const aMidProb = a.probs.filter((prob) => prob.prob >= 0.845);
        const bMidProb = b.probs.filter((prob) => prob.prob >= 0.845);
        if (aMidProb.length !== bMidProb.length) {
          return aMidProb.length - bMidProb.length;
        } else {
          const aAvgProfit = a.confidence;
          const bAvgProfit = b.confidence;
          return aAvgProfit - bAvgProfit;
        }
      }
    },
  },
];

interface AIModelPredictAggTableProps {
  setData?: (data: PredictAggregation[]) => void;
  apiFunction?: (params: any) => Promise<ApiResult<PredictAggregation[]>>;
  tableProps?: Partial<TableProps<PredictAggregation>>;
}

const AIModelPredictAggTable: FunctionComponent<AIModelPredictAggTableProps> = ({ setData, apiFunction, tableProps = {} }) => {
  return (
    <Table
      className="ai-modelpredict-aggregation-table"
      columns={COLUMNS.concat(StockDailyColumns as any)}
      // data={data}
      fetchAction={async (params) => {
        let response = null;
        if (!apiFunction) {
          response = await fetchModelPredictsAggregation(params);
        } else {
          response = await apiFunction(params);
        }
        setData && setData(response.data);
        return Promise.resolve(response);
      }}
      paginationConfig={{ per_page: 10 }}
      useRedux={false}
      sortInLocal={true}
      options={false}
      rowKey={(record) => `${record.info.code}_${record.date}`}
      {...tableProps}
    />
  );
};

export default AIModelPredictAggTable;
