import React, { FunctionComponent } from 'react';
import { Card } from 'antd';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import THSTopList from 'classify/components/THSTopList';
import TurnoverDashboard from 'market/components/TurnoverDashboard';
import MarginDashboard from 'market/components/MarginDashboard';
import { ClassifyTopType } from 'classify/models';
import THSTopTrender from 'classify/components/THSTopTrender';
import THSLatestSignals from 'classify/components/THSLatestSignals';
import IndicatorDashboard from 'market/components/IndicatorDashboard';
import Tabs from 'antd/lib/tabs';
import StockLimit from 'stock/components/StockLimit';

import './index.scss';

const Dashboard: FunctionComponent<{}> = () => {
  return (
    <WorkdeskLayout pageName="每日总览" className="daily-dashboard" title="每日总览">
      <Tabs>
        <Tabs.TabPane key="1" tab="市场">
          <IndicatorDashboard count={200} />
          <TurnoverDashboard count={120} />
          <MarginDashboard count={120} />
          {/* <HSGTDashboard count={120} /> */}
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="板块">
          <Card title="板块相对强度" bordered={false} className="section">
            <THSTopList topType={ClassifyTopType.RS} />
            <THSTopTrender topType={ClassifyTopType.RS} />
          </Card>
          <Card title="板块信号" bordered={false} className="section">
            <THSLatestSignals type="I" />
            <THSLatestSignals type="N" />
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab="涨停">
          <StockLimit />
        </Tabs.TabPane>
      </Tabs>
      {/* <Card title="市场概况" bordered={false} className="section market">
        <IndicatorDashboard count={200} />
        <TurnoverDashboard count={120} />
        <MarginDashboard count={120} />
        <HSGTDashboard count={120} />
      </Card> */}
      {/* <Card title="板块活跃度" bordered={false} className="section">
        <THSTopList topType={ClassifyTopType.HIT} />
        <THSTopTrender topType={ClassifyTopType.HIT} />
      </Card> */}
      {/* <Card title="板块整体表现" bordered={false} className="section">
        <THSTopList topType={ClassifyTopType.SCORE} />
        <THSTopTrender topType={ClassifyTopType.SCORE} />
      </Card> */}
      {/* <Card title="板块相对强度" bordered={false} className="section">
        <THSTopList topType={ClassifyTopType.RS_SIGNAL} />
        <THSTopTrender topType={ClassifyTopType.RS_SIGNAL} />
      </Card>
      <Card title="板块信号" bordered={false} className="section">
        <THSLatestSignals type="I" level={3} />
        <THSLatestSignals type="N" />
      </Card>
      <Card title="涨停概况" bordered={false} className="section">
        <StockLimitRangeStat />
        <StockLimitDailyStat />
        <StockLimitDetail />
      </Card>
      <Card title="信号概况" bordered={false} className="section">
        <SignalCountStatistic />
      </Card> */}
    </WorkdeskLayout>
  );
};

export default Dashboard;
