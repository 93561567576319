import { AIModelPredict } from 'ai/models';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import './index.scss';
import StockPriceChange from 'stock/components/StockPriceChange';
import { AlertOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

interface Props {
  predict: AIModelPredict;
}

const AIModePredictCard: FunctionComponent<Props> = ({ predict }) => {
  const [expand, setExpand] = React.useState(false);

  const className = classNames('ai-model-predict-card', {
    success: predict.max_chg && predict.label,
    fail: predict.max_chg && !predict.label,
    unknown: predict.max_chg === null,
    inactive: predict.prob < 0.795,
  });

  // const expectProfit = caculateExpectProfit(predict);
  // const modelExpected = caculateModelExpected(predict);
  // const statsExpected = caculateStatsExpected(predict);

  // let statsDetail;
  // const stats = predict.ai_model.spec.stats;

  // if (predict.prob >= 0.9) {
  //   statsDetail = stats.detail[0.9];
  // } else {
  //   statsDetail = stats.detail[0.8];
  // }

  let modelDetail;

  const details = predict.ai_model.detail;

  if (Array.isArray(details)) {
    modelDetail = details.filter((d) => d.threshold === 0.8)[0];
  } else {
    modelDetail = details;
  }

  return (
    <div
      className={className}
      onClick={() => {
        // 新开页面到模型详情
        window.open(`/ai/model/${predict.ai_model.id}`, '_blank');
      }}
    >
      <div
        className={classNames('prob', {
          alert: predict.ai_model.spec.target.chg_pct <= 0,
        })}
      >
        {predict.ai_model.spec.target.chg_pct <= 0 && <AlertOutlined />}
        {predict.prob.toFixed(3)}
      </div>
      {!expand && (
        <>
          <div className="model-info">
            <div>
              日均期望: <StockPriceChange pct_chg={predict.expected_return_per_day} />
            </div>
            <div className="model-precision">
                模型正确率:<span
                  style={{
                    color: modelDetail.val_pre > 50 ? 'red' : 'green',
                  }}
                >
                  {modelDetail.val_pre.toFixed(2)}%
                </span>
            </div>
            <div>
              模型日均期望: <StockPriceChange pct_chg={modelDetail.val_expected_return_per_day} />
            </div>
            {/* <div>AUC: {modelDetail.val_auc}</div> */}
            <div className="model-target">
              目标: 期限-{predict.ai_model.spec.target.time_limit} 收益-
              {predict.ai_model.spec.target.chg_pct}%
            </div>
            <div className="model-name">名称: {predict.ai_model.name}</div>
          </div>
          {/* <Button
            type="link"
            onClick={(e: any) => {
              setExpand(true);
              e.stopPropagation();
            }}
            style={{ float: 'right' }}
          >
            展开
          </Button> */}
        </>
      )}
      {/* <div>
        综合收益期望: <StockPriceChange pct_chg={predict.expected.total} />
      </div> */}
      {expand && (
        <>
          <div className="model-info">
            <div className="model-info-title">模型信息</div>
            <div className="model-precision">
                正确率:<span
                  style={{
                    color: modelDetail.val_pre > 50 ? 'red' : 'green',
                  }}
                >
                  {modelDetail.val_pre.toFixed(2)}%
                </span>
            </div>
            {/* <div>AUC: {modelDetail.val_auc}</div> */}
            <div>
              收益期望: <StockPriceChange pct_chg={predict.expected_return} />
            </div>
            <div>
              日均收益期望: <StockPriceChange pct_chg={predict.expected_return_per_day} />
            </div>
            <div className="model-target">
              目标: 期限-{predict.ai_model.spec.target.time_limit} 收益-
              {predict.ai_model.spec.target.chg_pct}%
            </div>
            <div className="model-name">名称: {predict.ai_model.name}</div>
            <div className="model-base">模型: {predict.ai_model.spec.model_base}</div>
            <div className="model-date">版本: {predict.ai_model.version}</div>
          </div>
          {/* <div className="model-info">
            近期表现
            <div>开始日期: {stats.start_date}</div>
            <div>结束日期: {stats.end_date}</div>
            <div>信号数: {statsDetail.positive_count}</div>
            <div>AUC: {statsDetail.positive_count === 0 ? '-' : stats.auc}</div>
            <div>
              正确率:
              {statsDetail.positive_count === 0 ? (
                ' -'
              ) : (
                <span
                  style={{
                    color: statsDetail.tp_pct > 0.5 ? 'red' : 'green',
                  }}
                >
                  {(statsDetail.tp_pct * 100).toFixed(2)}%
                </span>
              )}
            </div>
            <div>
              误判损失:
              {statsDetail.positive_count === 0 ? (
                ' -'
              ) : (
                <StockPriceChange pct_chg={statsDetail.fp_avg_loss} />
              )}
            </div>
            <div>
              收益期望:
              {statsDetail.positive_count === 0 ? (
                ' -'
              ) : (
                <StockPriceChange pct_chg={predict.expected.recent} />
              )}
            </div>
          </div> */}
          {predict.max_chg !== null && (
            <div className="predict-result">
              <div className="max-chg">最大涨幅： {predict.max_chg.toFixed(2)}%</div>
              <div className="loss">收盘卖出： {predict.loss.toFixed(2)}%</div>
            </div>
          )}
          <Button
            type="link"
            onClick={(e: any) => {
              setExpand(false);
              e.stopPropagation();
            }}
            style={{ float: 'right' }}
          >
            收起
          </Button>
        </>
      )}
    </div>
  );
};

export default AIModePredictCard;
